import React from 'react';
import './Form.css';

const FormSuccess = () => {
  return (
   <div className="form-content-right">
     <div className="form-success">Message sent</div>
     
   </div>
  )
};

export default FormSuccess;
